<template>
    <div>
        <b-overlay :show="loading" no-wrap></b-overlay>
        <b-row>
            <b-col sm="2" class="text-sm-left">
                <h3>{{ $t('multichannel.settings.store.label.magento_sets') }}</h3>
            </b-col>
            <b-col sm="8" class="text-sm-left">
                <h3>{{ $t('multichannel.settings.store.label.attribute_erika') }}</h3>
            </b-col>
            <b-col sm="2" class="text-sm-right">
                <b-button variant="primary"
                          class="mb-2"
                          @click="saveAttributesMapping"
                >
                    {{ $t('common.button.save') }}
                </b-button>
            </b-col>
        </b-row>
        <b-card no-body>
            <b-tabs v-model="activeTab" lazy pills card vertical @input="(val)=>{tabChanged(val)}">
                <b-tab v-for="set in getMagentoSets()"
                       :key="'set_tab_'+set.attribute_set_id"
                       :title="set.attribute_set_id + ' '+set.attribute_set_name"
                       @click="tabSelected(set)"
                >
                    <b-card-text v-if="setsMap[set.attribute_set_id]">
                        <template v-for="erikaSet in setsMap[set.attribute_set_id]" v-if="erikaSet">
                            <b-row>
                                <b-col cols="4">

                                    <zw-select-group v-model="erikaSet.erika_set_id"
                                                     :options="getAttributeSetsWithAttributes()"
                                                     name="erika_set_id"
                                                     label-prefix="multichannel.settings.store.label."
                                                     validate="required"
                                                     text-field="name"
                                                     value-field="id"
                                    ></zw-select-group>
                                </b-col>
                                <b-col cols="1" class="py-4" v-if="erikaSet.erika_set_id">
                                    <b-button @click="deleteErikaSet(set.attribute_set_id,erikaSet.erika_set_id)"
                                              size="sm"
                                              variant="danger"
                                              :title="$t('common.title.delete')"
                                    >
                                        <font-awesome-icon icon="trash"/>
                                    </b-button>
                                </b-col>
                            </b-row>
                            <b-row v-if="erikaSet.erika_set_id && getMagentoAttributes().length">
                                <b-col cols="4"
                                       v-for="attribute in getAttributesBySetId(erikaSet.erika_set_id)"
                                       :key="attribute.id">

                                    <b-form-group :label="attribute.name">
                                        <multiselect v-model="erikaSet.attributes[attribute.id]"
                                                     :options="getMagentoAttributes().map(attribute=>attribute.attribute_code)"
                                                     :multiple="false"
                                                     select-label=""
                                                     :custom-label="opt => {return getMagentoAttributes().find(attribute=>attribute.attribute_code==opt) ? getMagentoAttributes().find(attribute=>attribute.attribute_code==opt).attribute_code : ''}">
                                            >
                                        </multiselect>
                                    </b-form-group>
                                </b-col>
                            </b-row>
                        </template>
                        <b-row>
                            <b-col sm="4" class="text-sm-right">
                                <b-button block
                                          @click="addSet(set.attribute_set_id)"
                                          variant="primary"
                                >
                                    {{ $t('common.button.add') }}
                                </b-button>
                            </b-col>
                        </b-row>
                    </b-card-text>
                </b-tab>
            </b-tabs>
        </b-card>

        <b-row>
            <b-col sm="4" class="text-sm-right">
                <b-button block
                          @click="saveAttributesMapping"
                          variant="primary"
                >
                    {{ $t('common.button.save') }}
                </b-button>
            </b-col>
        </b-row>
    </div>
</template>

<script>
import commonSave from '@/bundles/erika_common_bundle/mixins/common-save'
import {mapGetters} from 'vuex'

export default {
    name: 'MagentoAttributes',
    mixins: [commonSave],
    props: {
        'channel': {
            type: Object,
            required: true
        },
    },
    data() {
        return {
            loading: true,
            setsMap: {},
            activeTab: null,
        }
    },
    mounted() {
        this.shown()
    },
    methods: {
        ...mapGetters('Attributes', ['getAttributeSetsWithAttributes']),
        ...mapGetters('Multichannel', ['getMagentoSets', 'getMagentoAttributes', 'getMagentoAttributesMap']),
        shown() {
            const sets = this.$store.dispatch('Multichannel/fetchMagentoSets', this.channel.id)
            const attributesMap = this.$store.dispatch('Multichannel/fetchMagentoAttributesMap')
            const attributeSetsWithAttributes = this.$store.dispatch('Attributes/fetchAttributeSetsWithAttributes')

            Promise.all([sets, attributeSetsWithAttributes, attributesMap])
                .then(() => {
                    this.getMagentoSets().forEach(set => {
                        this.$set(this.setsMap, set.attribute_set_id, [{erika_set_id: null, attributes: {}}])
                    })

                    // restore data from api
                    this.getMagentoAttributesMap().forEach(set => {
                        if (this.setsMap[set.set_id]) {
                            this.setsMap[set.set_id].push({
                                erika_set_id: set.erika_set_id,
                                attributes: set.attributes_map
                            });
                        }
                    })
                    this.tabSelected(this.getMagentoSets()[0])
                })
                .finally(() => {
                    this.loading = false
                })
        },
        deleteErikaSet(attribute_set_id, erika_set_id) {
            this.$store.dispatch('Multichannel/unassignMagentoAttributes', {
                attributeSetId: attribute_set_id,
                erikaSetId: erika_set_id
            })
                .then(() => {
                    this.shown();
                })
                .finally(() => {
                    this.loading = false
                })
        },
        addSet(setId) {
            this.setsMap[setId].push({erika_set_id: null, attributes: {}});
        },
        tabSelected(val) {
            this.$store.dispatch('Multichannel/fetchMagentoAttributes', {
                sourceId: this.channel.id,
                setId: val.attribute_set_id
            })
                .then(() => {

                })
                .finally(() => {
                    this.loading = false
                })
        },
        getAttributesBySetId(id) {
            const attributeSet = this.getAttributeSetsWithAttributes().find(attributeSet => attributeSet.id == id)
            if (attributeSet) {
                return attributeSet.project_attributes
            }
            return []
        },
        saveAttributesMapping() {
            this.loading = true
            this.$store.dispatch('Multichannel/saveMagentoAttributes', this.setsMap)
                .then((response) => {
                    if (response.status == 200 || response.status == 201) {
                        this.$root.showMessage(
                            this.$t('common.form.title.success'),
                            this.$t('common.toasts.success_updated'),
                            'success'
                        )
                        this.shown();
                    }
                })
                .finally(() => {
                    this.loading = false
                })
        },
        tabChanged() {
            this.$root.$children[0].updateFormValues('channel-settings-modal')
        },
    }
}
</script>