<template>
    <div>
        <b-overlay :show="loading" no-wrap></b-overlay>
        <b-row>
            <b-col></b-col>
            <b-col cols="4">
                <b-row>
                    <b-col cols="9">
                        <zw-input-group v-model="newStatus"
                                        name="new_status"
                                        disable-label
                                        :label-prefix="labelPrefix"
                        ></zw-input-group>
                    </b-col>
                    <b-col cols="3">
                        <b-button variant="primary"
                                  class="mb-2"
                                  block
                                  :disabled="!newStatus.length"
                                  @click="createStatus"
                        >
                            <font-awesome-icon icon="plus"/>
                            {{ $t('common.button.create') }}
                        </b-button>
                    </b-col>
                </b-row>
            </b-col>
        </b-row>

        <b-table-simple striped>
            <b-thead head-variant="info">
                <b-th>{{ $t('multichannel.settings.label.status') }}</b-th>
                <b-th>{{ $t('multichannel.settings.label.actions') }}</b-th>
            </b-thead>
            <tbody>
            <b-tr v-for="status in channel.order_statuses" :key="status">
                <b-td>{{ status }}</b-td>
                <b-td>
                    <b-button @click="deleteStatus(status)"
                              size="sm"
                              variant="danger"
                              :title="$t('common.title.delete')"
                    >
                        <font-awesome-icon icon="trash"/>
                    </b-button>
                </b-td>
            </b-tr>
            </tbody>
        </b-table-simple>
    </div>
</template>

<script>
import commonSave from '@/bundles/erika_common_bundle/mixins/common-save'

export default {
    name: 'ChannelStatuses',
    mixins: [commonSave],
    props: {
        'channel': {
            type: Object,
            required: true
        },
    },
    data() {
        return {
            loading: true,
            newStatus: '',
            labelPrefix: 'multichannel.settings.label.',
        }
    },
    mounted() {
        this.loading = false
    },
    methods: {
        createStatus() {
            this.loading = true
            this.$store.dispatch('Channel/saveStatus', {
                id: this.channel.id,
                status: this.newStatus,
            })
                .finally(() => {
                    this.newStatus = ''
                    this.loading = false
                })
        },
        deleteStatus(status) {
            this.loading = true
            this.$store.dispatch('Channel/deleteStatus', {
                id: this.channel.id,
                status: status,
            })
                .finally(() => {
                    this.loading = false
                })
        },
    }
}
</script>