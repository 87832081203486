<template>
    <div>
        <b-overlay :show="loading" no-wrap></b-overlay>
        <b-row>
            <b-col sm="12" class="text-sm-right">
                <b-button @click="rightSyncCategories()"
                          variant="info"
                          class="mb-2 mr-2"
                >
                    <font-awesome-icon icon="cloud-download-alt"/>
                    {{ $t('multichannel.settings.store.button.sync_otto_categories') }}
                </b-button>
                <b-button @click="saveCategoryMapping()"
                          variant="primary"
                          class="mb-2"
                >
                    {{ $t('common.button.save') }}
                </b-button>
            </b-col>
        </b-row>
        <b-card no-body>
            <b-tabs pills card vertical @input="(val)=>{tabChanged(val)}">
                <b-tab v-for="set in getAttributeSetsWithAttributes()"
                       :key="'category_tab_'+set.id"
                       :title="set.name"
                >
                    <b-card-text v-if="categoriesMap[set.id]">
                        <b-row>
                            <b-col cols="4">
                                <search-field v-model="categoriesMap[set.id].category"
                                              name="channel_category"
                                              label-prefix="multichannel.settings.store.label."
                                              :url="channelCategorySearchUrl"
                                              :placeholder="$t('common.label.common_search')"
                                              @input-object="categoryLoaded"
                                ></search-field>
                            </b-col>
                        </b-row>
                        <b-row v-if="set.project_attributes.length">
                            <b-col cols="4" v-for="attribute in set.project_attributes" :key="attribute.id">
                                <b-form-group :label="attribute.name">
                                    <multiselect v-model="categoriesMap[set.id].attributes[attribute.id]"
                                                 :options="getAttributesByCategoryId(categoriesMap[set.id].category)"
                                                 :multiple="true"
                                                 select-label=""
                                    ></multiselect>
                                </b-form-group>
                            </b-col>
                        </b-row>
                    </b-card-text>
                </b-tab>
            </b-tabs>
        </b-card>

        <b-row>
            <b-col sm="12" class="text-sm-right">
                <b-button block
                          @click="saveCategoryMapping()"
                          variant="primary"
                >
                    {{ $t('common.button.save') }}
                </b-button>
            </b-col>
        </b-row>
    </div>
</template>

<script>
import commonSave from '@/bundles/erika_common_bundle/mixins/common-save'
import {mapGetters} from 'vuex'

export default {
    name: 'OttoCategories',
    mixins: [commonSave],
    props: {
        'channel': {
            type: Object,
            required: true
        },
    },
    data() {
        return {
            loading: true,
            categoriesMap: {},
            loadedCategories: {},
            channelCategorySearchUrl: window.apiUrl + '/multi-channel/channel-category/search',
        }
    },
    mounted() {
        this.shown()
    },
    methods: {
        ...mapGetters('Multichannel', ['getOttoCategories']),
        ...mapGetters('Attributes', ['getAttributeSetsWithAttributes']),
        shown() {
            const ottoCategories = this.$store.dispatch('Multichannel/fetchOttoCategories')
            const attributeSetsWithAttributes = this.$store.dispatch('Attributes/fetchAttributeSetsWithAttributes')

            Promise.all([ottoCategories, attributeSetsWithAttributes])
                .then(() => {
                    // first set default data
                    this.getAttributeSetsWithAttributes().forEach(set => {
                        this.$set(this.categoriesMap, set.id, {category: null, attributes: {}})
                    })
                    // restore data from api
                    this.getOttoCategories().forEach(category => {
                        this.$set(this.categoriesMap, category.category_id, {
                            category: category.foreign_category_id,
                            attributes: category.attributes_map
                        })
                    })
                })
                .finally(() => {
                    this.loading = false
                })
        },
        saveCategoryMapping() {
            this.loading = true
            this.$store.dispatch('Multichannel/saveOttoCategories', this.categoriesMap)
                .then((response) => {
                    if (response.status == 200 || response.status == 201) {
                        this.$root.showMessage(
                            this.$t('common.form.title.success'),
                            this.$t('common.toasts.success_updated'),
                            'success'
                        )
                    }
                })
                .finally(() => {
                    this.loading = false
                })
        },
        categoryLoaded(category) {
            this.$set(this.loadedCategories, category.id, category)
        },
        getAttributesByCategoryId(id) {
            if (this.loadedCategories[id]) {
                const attributes = this.loadedCategories[id].attributes
                return attributes.map(attribute => {
                    return attribute.name
                })
            }
            return []
        },
        rightSyncCategories() {
            this.loading = true
            this.$store.dispatch('Multichannel/syncCategories', this.channel.id)
                .finally(() => {
                    this.shown()
                })
        },
        tabChanged() {
            this.$root.$children[0].updateFormValues('channel-settings-modal')
        },
    }
}
</script>