<template>
    <div>
        <b-overlay :show="loading" no-wrap></b-overlay>
        <b-card no-body>
            <b-tabs pills card vertical @input="(val)=>{tabChanged(val)}">
                <b-tab v-for="attribute in getAmazonAttributes()"
                       :key="'tab_'+attribute.id"
                       :title="attribute.name"
                >
                    <b-card-text>
                        <template v-if="attribute.type == 'simple'">
                            <b-row>
                                <b-col>
                                    <zw-select-group v-model="attribute.target"
                                                     :options="simpleAttributes"
                                                     name="internal_attribute"
                                                     label-prefix="multichannel.settings.store.label."
                                                     value-field="id"
                                                     text-field="name"
                                                     empty
                                    >
                                    </zw-select-group>
                                </b-col>
                                <b-col>
                                    <zw-input-group v-model="attribute.default_value"
                                                    name="default_value"
                                                    label-prefix="multichannel.settings.store.label."
                                    ></zw-input-group>
                                </b-col>
                            </b-row>
                        </template>
                        <template v-else>
                            <b-row>
                                <b-col>
                                    <zw-select-group v-model="attribute.target"
                                                     :options="simpleAttributes"
                                                     name="internal_attribute"
                                                     label-prefix="multichannel.settings.store.label."
                                                     value-field="id"
                                                     text-field="name"
                                                     empty
                                    >
                                    </zw-select-group>
                                </b-col>
                                <b-col>
                                    <zw-select-group v-model="attribute.default_value"
                                                     :options="Object.keys(attribute.map)"
                                                     name="default_value"
                                                     label-prefix="multichannel.settings.store.label."
                                                     empty
                                    >
                                    </zw-select-group>
                                </b-col>
                            </b-row>
                            <b-row>
                                <b-col cols="4" v-for="(value, key) in attribute.map" :key="key">
                                    <b-form-group :label="key">
                                        <multiselect v-model="attribute.map[key]"
                                                     :options="getOptionsByAttributeId(attribute.target)"
                                                     :multiple="true"
                                                     select-label=""
                                        ></multiselect>
                                    </b-form-group>
                                </b-col>
                            </b-row>
                        </template>

                    </b-card-text>
                </b-tab>
            </b-tabs>
        </b-card>

        <b-row>
            <b-col sm="12" class="text-sm-right">
                <b-button block
                          @click="saveAttributeMapping()"
                          variant="primary"
                >
                    {{ $t('common.button.save') }}
                </b-button>
            </b-col>
        </b-row>
    </div>
</template>

<script>
import commonSave from '@/bundles/erika_common_bundle/mixins/common-save'
import {mapGetters} from 'vuex'

export default {
    name: 'AmazonAttributes',
    mixins: [commonSave],
    methods: {
        ...mapGetters('Multichannel', ['getAmazonAttributes', 'getProjectAttributes']),
        saveAttributeMapping() {
            this.loading = true
            this.$store.dispatch('Multichannel/saveAmazonAttributes', this.getAmazonAttributes())
                .then((response) => {
                    if (response.status == 200 || response.status == 201) {
                        this.$root.showMessage(
                            this.$t('common.form.title.success'),
                            this.$t('common.toasts.success_updated'),
                            'success'
                        )
                    }
                })
                .finally(() => {
                    this.loading = false
                })
        },
        getOptionsByAttributeId(id) {
            const attribute = this.getProjectAttributes().find(attribute => attribute.id == id) || null
            if (attribute) {
                return attribute.options
            }
            return []
        },
        tabChanged() {
            this.$root.$children[0].updateFormValues('channel-settings-modal')
        },
    },
    data() {
        return {
            loading: true,
        }
    },
    mounted() {
        const amazonAttributes = this.$store.dispatch('Multichannel/fetchAmazonAttributes')

        Promise.all([amazonAttributes])
            .finally(() => {
                this.loading = false
            })
    },
    computed: {
        simpleAttributes: function () {
            return this.getProjectAttributes().map(attribute => {
                return {
                    'id': attribute.id,
                    'name': attribute.name,
                }
            })
        },
    }
}
</script>