<template>
    <zw-sidebar @shown="shown" :title="$t('multichannel.settings.title')">
        <b-tabs v-if="!loading" lazy content-class="mt-3" @input="(val)=>{tabChanged(val)}">
            <b-tab>
                <template slot="title">
                    <font-awesome-icon icon="cloud"/>
                    {{ $t('multichannel.settings.tabs.stores') }}
                </template>

                <channel-stores :channel="getSource()"></channel-stores>
            </b-tab>
            <b-tab>
                <template slot="title">
                    <font-awesome-icon icon="history"></font-awesome-icon>
                    {{ $t('multichannel.settings.tabs.statuses') }}
                </template>

                <channel-statuses :channel="getSource()"></channel-statuses>
            </b-tab>
            <b-tab>
                <template slot="title">
                    <font-awesome-icon icon="link"></font-awesome-icon>
                    {{ $t('multichannel.settings.tabs.status_map') }}
                </template>
                <status-map :channel="getSource()"></status-map>
            </b-tab>
            <b-tab v-if="getSource().api_type == 'otto-market'">
                <template slot="title">
                    <font-awesome-icon icon="link"></font-awesome-icon>
                    {{ $t('multichannel.settings.tabs.category_map') }}
                </template>

                <otto-categories :channel="getSource()"></otto-categories>
            </b-tab>
            <b-tab v-if="getSource().api_type == 'woocommerce'">
                <template slot="title">
                    <font-awesome-icon icon="link"></font-awesome-icon>
                    {{ $t('multichannel.settings.tabs.category_map') }}
                </template>

                <woocommerce-categories :channel="getSource()"></woocommerce-categories>
            </b-tab>
            <b-tab v-if="getSource().api_type == 'otto-market'">
                <template slot="title">
                    <font-awesome-icon icon="link"></font-awesome-icon>
                    {{ $t('multichannel.settings.tabs.brand_map') }}
                </template>

                <otto-brand :channel="getSource()"></otto-brand>
            </b-tab>
            <b-tab v-if="getSource().api_type == 'amazon'">
                <template slot="title">
                    <font-awesome-icon icon="link"></font-awesome-icon>
                    {{ $t('multichannel.settings.tabs.attribute_map') }}
                </template>

                <amazon-attributes></amazon-attributes>
            </b-tab>
            <b-tab v-if="getSource().api_type == 'magento'">
                <template slot="title">
                    <font-awesome-icon icon="link"></font-awesome-icon>
                    {{ $t('multichannel.settings.tabs.attribute_map') }}
                </template>

                <magento-attributes :channel="getSource()"></magento-attributes>
            </b-tab>
        </b-tabs>
    </zw-sidebar>
</template>

<script>
import {mapGetters} from 'vuex'
import commonSave from '@/bundles/erika_common_bundle/mixins/common-save'
import OttoBrand from "./channel-tabs/OttoBrand";
import AmazonAttributes from "./channel-tabs/AmazonAttributes";
import OttoCategories from "./channel-tabs/OttoCategories";
import WoocommerceCategories from "./channel-tabs/WoocommerceCategories";
import StatusMap from "./channel-tabs/StatusMap";
import ChannelStatuses from "./channel-tabs/ChannelStatuses";
import ChannelStores from "./channel-tabs/Stores";
import MagentoAttributes from "./channel-tabs/MagentoAttributes";

export default {
    name: 'ChannelSettings-modal',
    components: {
        MagentoAttributes,
        ChannelStores, ChannelStatuses, StatusMap, OttoCategories, WoocommerceCategories, AmazonAttributes, OttoBrand
    },
    mixins: [commonSave],
    data() {
        return {
            loading: true,
            payload: {},
            callback: null,
        }
    },
    methods: {
        ...mapGetters('Channel', ['getSource']),
        shown() {
            const channel = this.$store.dispatch('Channel/fetchSource', this.payload.id)

            Promise.all([channel])
                .finally(() => {
                    this.loading = false
                })
        },
        tabChanged() {
            this.$root.$children[0].updateFormValues('channel-settings-modal')
        },
    },
}
</script>