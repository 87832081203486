<template>
    <div>
        <b-overlay :show="loading" no-wrap></b-overlay>
        <b-row>
            <b-col sm="12" class="text-sm-right">
                <b-button @click="syncCategories()"
                          variant="info"
                          class="mb-2 mr-2"
                >
                    <font-awesome-icon icon="cloud-download-alt"/>
                    {{ $t('multichannel.settings.store.button.sync_woocommerce_categories') }}
                </b-button>
                <b-button @click="saveCategoryMapping()"
                          variant="primary"
                          class="mb-2"
                >
                    {{ $t('common.button.save') }}
                </b-button>
            </b-col>
        </b-row>
        <b-card no-body>
            <b-tabs pills card vertical @input="(val)=>{tabChanged(val)}">
                <b-tab v-for="category in getCategories()"
                       :key="'category_tab_'+category.id"
                       :title="category.name"
                >
                    <b-card-text v-if="categoriesMap[category.id]">
                        <b-row>
                            <b-col cols="4">
                                <search-field v-model="categoriesMap[category.id].category"
                                              name="channel_category"
                                              label-prefix="multichannel.settings.store.label."
                                              :url="channelCategorySearchUrl"
                                              :placeholder="$t('common.label.common_search')"
                                              @input-object="categoryLoaded"
                                ></search-field>
                            </b-col>
                        </b-row>
                    </b-card-text>
                </b-tab>
            </b-tabs>
        </b-card>

        <b-row>
            <b-col sm="12" class="text-sm-right">
                <b-button block
                          @click="saveCategoryMapping()"
                          variant="primary"
                >
                    {{ $t('common.button.save') }}
                </b-button>
            </b-col>
        </b-row>
    </div>
</template>

<script>
import commonSave from '@/bundles/erika_common_bundle/mixins/common-save'
import {mapGetters} from 'vuex'

export default {
    name: 'WoocommerceCategories',
    mixins: [commonSave],
    props: {
        'channel': {
            type: Object,
            required: true
        },
    },
    data() {
        return {
            loading: true,
            categoriesMap: {},
            loadedCategories: {},
            channelCategorySearchUrl: window.apiUrl + '/multi-channel/channel-category/woocommerce-search',
        }
    },
    mounted() {
        this.shown()
    },
    methods: {
        ...mapGetters('Multichannel', ['getWoocommerceCategories']),
        ...mapGetters('Category', ['getCategories']),
        shown() {
            const woocommerceCategories = this.$store.dispatch('Multichannel/fetchWoocommerceCategories')
            const categories = this.$store.dispatch('Category/fetchCategories')

            Promise.all([woocommerceCategories, categories])
                .then(() => {
                    // first set default data
                    this.getCategories().forEach(category => {
                        this.$set(this.categoriesMap, category.id, {category: null})
                    })
                    // restore data from api
                    this.getWoocommerceCategories().forEach(category => {
                        this.$set(this.categoriesMap, category.category_id, {
                            category: category.foreign_category_id
                        })
                    })
                })
                .finally(() => {
                    this.loading = false
                })
        },
        saveCategoryMapping() {
            this.loading = true
            this.$store.dispatch('Multichannel/saveWoocommerceCategories', this.categoriesMap)
                .then((response) => {
                    if (response.status == 200 || response.status == 201) {
                        this.$root.showMessage(
                            this.$t('common.form.title.success'),
                            this.$t('common.toasts.success_updated'),
                            'success'
                        )
                    }
                })
                .finally(() => {
                    this.loading = false
                })
        },
        categoryLoaded(category) {
            this.$set(this.loadedCategories, category.id, category)
        },
        syncCategories() {
            this.loading = true
            this.$store.dispatch('Multichannel/syncWoocommerceCategories', this.channel.id)
                .finally(() => {
                    this.shown()
                })
        },
        tabChanged() {
            this.$root.$children[0].updateFormValues('channel-settings-modal')
        },
    }
}
</script>