<template>
    <div>
        <b-overlay :show="loading" no-wrap></b-overlay>
        <b-row>
            <b-col cols="4">
                <search-field v-model="brand"
                              name="channel_brand"
                              label-prefix="multichannel.settings.store.label."
                              :url="channelBrandSearchUrl"
                              :placeholder="$t('common.label.common_search')"
                ></search-field>
            </b-col>
        </b-row>
        <b-row>
            <b-col sm="12" class="text-sm-right">
                <b-button block
                          @click="save()"
                          variant="primary"
                >
                    {{ $t('common.button.save') }}
                </b-button>
            </b-col>
        </b-row>
    </div>

</template>

<script>
import commonSave from '@/bundles/erika_common_bundle/mixins/common-save'

export default {
    name: 'OttoBrand',
    mixins: [commonSave],
    props: {
        'channel': {
            type: Object,
            required: true
        },
    },
    data() {
        return {
            loading: true,
            brand: null,
            channelBrandSearchUrl: window.apiUrl + '/multi-channel/brand/search',
        }
    },
    mounted() {
        this.brand = this.channel.default_brand

        this.loading = false
    },
    methods: {
        save() {
            this.loading = true

            this.$store.dispatch('Channel/updateSource', {
                id: this.channel.id,
                default_brand: this.brand,
            })
                .then((response) => {
                    if (response.status == 200 || response.status == 201) {
                        this.$root.showMessage(
                            this.$t('common.form.title.success'),
                            this.$t('common.toasts.success_updated'),
                            'success'
                        )
                    }
                })
                .finally(() => {
                    this.loading = false
                })
        }
    }
}
</script>