<template>
    <div>
        <b-overlay :show="loading" no-wrap></b-overlay>
        <b-card :header="$t('common.group.offering_status')">
            <b-card-text>
                <b-row v-if="!loading">
                    <b-col cols="3" v-for="(status,index) in getStatuses()" :key="'map_'+index">

                        <b-form-group>
                            <template v-slot:label>
                                {{ status.value }}
                            </template>

                            <multiselect v-model="statusesMap[status.value]"
                                         :options="Object.values(channel.order_statuses || [])"
                                         :searchable="true"
                                         :multiple="true"
                                         placeholder=""
                                         select-label=""
                            ></multiselect>
                        </b-form-group>
                    </b-col>
                </b-row>
            </b-card-text>
        </b-card>

        <b-card :header="$t('common.group.payment_status')">
            <b-card-text>
                <b-row v-if="!loading">
                    <b-col cols="3" v-for="(status,index) in this.getEntityStatuses()['payment']"
                           :key="'payment_map_'+index">
                        <b-form-group>
                            <template v-slot:label>
                                {{ status.value }}
                            </template>

                            <multiselect v-model="paymentStatusesMap[status.value]"
                                         :options="Object.values(channel.payment_statuses || [])"
                                         :searchable="true"
                                         :multiple="true"
                                         placeholder=""
                                         select-label=""
                            ></multiselect>
                        </b-form-group>
                    </b-col>
                </b-row>
            </b-card-text>
        </b-card>

        <b-card :header="$t('common.group.shipping_status')">
            <b-card-text>
                <b-row v-if="!loading">
                    <b-col cols="3" v-for="(status,index) in getShippingStatuses()" :key="'shipping_map_'+index">
                        <b-form-group>
                            <template v-slot:label>
                                {{ status }}
                            </template>

                            <multiselect v-model="shippingStatusesMap[status]"
                                         :options="Object.values(channel.shipping_statuses || [])"
                                         :searchable="true"
                                         :multiple="true"
                                         placeholder=""
                                         select-label=""
                            ></multiselect>
                        </b-form-group>
                    </b-col>
                </b-row>
            </b-card-text>
        </b-card>

        <b-card :header="$t('common.group.fulfilment_status')">
            <b-card-text>
                <b-row v-if="!loading">
                    <b-col cols="3" v-for="(status,index) in getFulfilmentStatuses()" :key="'filfilment_map_'+index">
                        <b-form-group>
                            <template v-slot:label>
                                {{ status }}
                            </template>

                            <multiselect v-model="fulfilmentStatusesMap[status]"
                                         :options="Object.values(channel.fulfilment_statuses || [])"
                                         :searchable="true"
                                         :multiple="true"
                                         placeholder=""
                                         select-label=""
                            ></multiselect>
                        </b-form-group>
                    </b-col>
                </b-row>
            </b-card-text>
        </b-card>

        <b-card :header="$t('common.group.payment_methods')">
            <b-card-text>
                <b-row v-if="!loading">
                    <b-col cols="3" v-for="(status,index) in getPaymentMethods()" :key="'payment_methods_map_'+index">
                        <b-form-group>
                            <template v-slot:label>
                                {{ status }}
                            </template>

                            <multiselect v-model="paymentMethodsMap[index]"
                                         :options="Object.values(channel.payment_methods || [])"
                                         :searchable="true"
                                         :multiple="true"
                                         placeholder=""
                                         select-label=""
                            ></multiselect>
                        </b-form-group>
                    </b-col>
                </b-row>
            </b-card-text>
        </b-card>

        <b-card :header="$t('common.group.shipping_methods')">
            <b-card-text>
                <b-row v-if="!loading">
                    <b-col cols="3" v-for="(status,index) in getShippingMethods()" :key="'shipping_methods_map_'+index">
                        <b-form-group>
                            <template v-slot:label>
                                {{ status }}
                            </template>

                            <multiselect v-model="shippingMethodsMap[index]"
                                         :options="Object.values(channel.shipping_methods || [])"
                                         :searchable="true"
                                         :multiple="true"
                                         placeholder=""
                                         select-label=""
                            ></multiselect>
                        </b-form-group>
                    </b-col>
                </b-row>
            </b-card-text>
        </b-card>

        <b-row>
            <b-col sm="12" class="text-sm-right">
                <b-button block
                          @click="saveStatusMapping()"
                          variant="primary"
                >
                    {{ $t('common.button.save') }}
                </b-button>
            </b-col>
        </b-row>
    </div>

</template>

<script>
import commonSave from '@/bundles/erika_common_bundle/mixins/common-save'
import {mapGetters} from 'vuex'

export default {
    name: 'StatusMap',
    mixins: [commonSave],
    props: {
        'channel': {
            type: Object,
            required: true
        },
    },
    data() {
        return {
            loading: true,
            statusesMap: {},
            shippingStatusesMap: {},
            paymentStatusesMap: {},
            fulfilmentStatusesMap: {},
            paymentMethodsMap: {},
            shippingMethodsMap: {},
        }
    },
    mounted() {
        const statusesFetch = this.$store.dispatch('Status/fetchStatuses')
        const paymentMethodsFetch = this.$store.dispatch('Offering/fetchPaymentMethods', 0)

        Promise.all([statusesFetch, paymentMethodsFetch])
            .then(() => {
                this.getStatuses().forEach(status => {
                    this.$set(this.statusesMap, status.value, [])
                })
                this.getShippingStatuses().forEach(status => {
                    this.$set(this.shippingStatusesMap, status, [])
                })
                this.getEntityStatuses()['payment'].forEach(status => {
                    this.$set(this.paymentStatusesMap, status.value, [])
                })
                this.getFulfilmentStatuses().forEach(status => {
                    this.$set(this.fulfilmentStatusesMap, status, [])
                })
                Object.keys(this.getPaymentMethods()).forEach(status => {
                    this.$set(this.paymentMethodsMap, status, [])
                })
                Object.keys(this.getShippingMethods()).forEach(status => {
                    this.$set(this.shippingMethodsMap, status, [])
                })

                const kvaStatuses = this.channel.kva_statuses || {}
                const paymentStatuses = this.channel.payment_status_map || {}
                const shippingStatuses = this.channel.shipping_status_map || {}
                const fulfilmentStatuses = this.channel.fulfilment_statuses_map || {}
                const paymentMethods = this.channel.payment_methods_map || {}
                const shippingMethods = this.channel.shipping_methods_map || {}

                Object.keys(kvaStatuses).forEach(key => {
                    if (Object.keys(this.statusesMap).includes(key)) {
                        this.$set(this.statusesMap, key, kvaStatuses[key])
                    }
                })
                Object.keys(paymentStatuses).forEach(key => {
                    if (Object.keys(this.paymentStatusesMap).includes(key)) {
                        this.$set(this.paymentStatusesMap, key, paymentStatuses[key])
                    }
                })
                Object.keys(shippingStatuses).forEach(key => {
                    if (Object.keys(this.shippingStatusesMap).includes(key)) {
                        this.$set(this.shippingStatusesMap, key, shippingStatuses[key])
                    }
                })
                Object.keys(fulfilmentStatuses).forEach(key => {
                    if (Object.keys(this.fulfilmentStatusesMap).includes(key)) {
                        this.$set(this.fulfilmentStatusesMap, key, fulfilmentStatuses[key])
                    }
                })
                Object.keys(paymentMethods).forEach(key => {
                    if (Object.keys(this.paymentMethodsMap).includes(key)) {
                        this.$set(this.paymentMethodsMap, key, paymentMethods[key])
                    }
                })
                Object.keys(shippingMethods).forEach(key => {
                    if (Object.keys(this.shippingMethodsMap).includes(key)) {
                        this.$set(this.shippingMethodsMap, key, shippingMethods[key])
                    }
                })
            })
            .finally(() => {
                this.loading = false
            })
    },
    methods: {
        ...mapGetters('Status', ['getStatuses']),
        ...mapGetters('Offering', ['getPaymentMethods']),
        ...mapGetters('CommonData', ['getShippingStatuses', 'getEntityStatuses', 'getFulfilmentStatuses', 'getShippingMethods']),
        saveStatusMapping() {
            this.loading = true
            this.$store.dispatch('Channel/saveStatusMap', {
                id: this.channel.id,
                map: this.statusesMap,
                payment_statuses_map: this.paymentStatusesMap,
                fulfilment_statuses_map: this.fulfilmentStatusesMap,
                shipping_status_map: this.shippingStatusesMap,
                shipping_methods_map: this.shippingMethodsMap,
                payment_methods_map: this.paymentMethodsMap,
            })
                .then((response) => {
                    if (response.status == 200 || response.status == 201) {
                        this.$root.showMessage(
                            this.$t('common.form.title.success'),
                            this.$t('common.toasts.success_updated'),
                            'success'
                        )
                    }
                })
                .finally(() => {
                    this.loading = false
                })
        },
    }
}
</script>