<template>
    <div>
        <b-overlay :show="loading" no-wrap></b-overlay>
        <b-row v-if="channel.api_type == 'magento'">
            <b-col cols="12" class="text-right">
                <b-button variant="primary"
                          class="mb-2"
                          @click="rightSyncStores"
                >
                    <font-awesome-icon icon="cloud-download-alt"/>
                    {{ $t('multichannel.settings.store.button.sync_magento') }}
                </b-button>
            </b-col>
        </b-row>
        <b-row v-if="channel.api_type == 'amazon' || channel.api_type=='ebay' ">
            <b-col cols="12" class="text-right">
                <b-button variant="primary"
                          class="mb-2"
                          @click="createStore"
                >
                    <font-awesome-icon icon="plus"/>
                    {{ $t('multichannel.settings.store.button.create') }}
                </b-button>
            </b-col>
        </b-row>

        <b-table-simple striped>
            <b-thead head-variant="info">
                <b-th>{{ $t('multichannel.settings.store.label.id') }}</b-th>
                <b-th>{{ $t('multichannel.settings.store.label.name') }}</b-th>
                <b-th>{{ $t('multichannel.settings.store.label.code') }}</b-th>
                <b-th>{{ $t('multichannel.settings.store.label.invoice_number') }}</b-th>
                <b-th>{{ $t('multichannel.settings.store.label.language') }}</b-th>
                <b-th>{{ $t('multichannel.settings.store.label.currency') }}</b-th>
                <b-th>{{ $t('multichannel.settings.store.label.currency_short') }}</b-th>
                <b-th>{{ $t('multichannel.settings.store.label.default') }}</b-th>
                <b-th>{{ $t('multichannel.settings.store.label.default_vat') }}</b-th>
                <b-th>{{ $t('multichannel.settings.store.label.actions') }}</b-th>
            </b-thead>
            <tbody>
            <b-tr v-for="store in getStores()" :key="store.id">
                <b-td>{{ store.id }}</b-td>
                <b-td>{{ store.name }}</b-td>
                <b-td>{{ store.code }}</b-td>
                <b-td>{{ store.rechnungsnummer }}</b-td>
                <b-td>{{ store.locale }}</b-td>
                <b-td>{{ store.waehrung }}</b-td>
                <b-td>{{ store.waehrung_short }}</b-td>
                <b-td>{{ store.is_default ? $t('common.button.yes') : $t('common.button.no') }}</b-td>
                <b-td>{{ store.default_vat }}</b-td>
                <b-td>
                    <b-button
                        @click="$root.$children[0].openModal('store-modal', {id: store.id,api_type: channel.api_type},shown,{width: '800px'})"
                        size="sm"
                        variant="primary"
                        class="mr-2"
                        :title="$t('common.title.edit')"
                    >
                        <font-awesome-icon icon="edit"/>
                    </b-button>
                </b-td>
            </b-tr>
            </tbody>
        </b-table-simple>
    </div>
</template>

<script>
import commonSave from '@/bundles/erika_common_bundle/mixins/common-save'
import {mapGetters} from 'vuex'

export default {
    name: 'ChannelStores',
    mixins: [commonSave],
    props: {
        'channel': {
            type: Object,
            required: true
        },
    },
    data() {
        return {
            loading: true,
        }
    },
    mounted() {
        this.shown()
    },
    methods: {
        ...mapGetters('Multichannel', ['getStores']),
        rightSyncStores() {
            this.loading = true
            this.$store.dispatch('Multichannel/syncStores', this.channel.id)
                .finally(() => {
                    this.shown()
                })

        },
        shown() {
            const stores = this.$store.dispatch('Multichannel/fetchStores', this.channel.id)

            Promise.all([stores])
                .finally(() => {
                    this.loading = false
                })
        },
        createStore() {
            this.$root.$children[0].openModal('store-modal', {
                id: null,
                channel_id: this.channel.id,
                api_type: this.channel.api_type
            }, this.shown, {width: '800px'})
        }
    }
}
</script>